<template>
  <div class="md:w-5/12 lg:w-4/12 xl:w-3/12 mx-auto pt-16">
    <Steps class="mb-12"/>
    <h2 class="mb-20 heading-m-700">How do you want to use the app?</h2>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-y-0 md:gap-x-5 mb-10">
      <div :class="[{borderActive:type === 'pro'}, {borderInactive:type === 'church'}, type == 'pro' ? 'bg-softBlue' : 'bg-white']" 
        class="card"
        @click="setType('pro')"
      >
        <span :class="type === 'pro' ? 'bg-white' : 'bg-transparent'"
              class="rounded-full bg-white w-20 h-20 flex justify-center items-center">
          <UserCircle class="text-auxiliary-blue"/>
        </span>
        <span class="block my-4 text-auxiliary-blue body-m-700">Pro</span>
        <p class="text-center body-xs-400">I am a pro wanting to work with businesses.</p>
      </div>
      <div :class="[{borderActive:type === 'church'}, {borderInactive:type === 'pro'}, type == 'church' ? 'bg-softBlue' : 'bg-white']" 
        class="card"
        @click="openSignupModal"
      >
        <span :class="type === 'church' ? 'bg-white' : 'bg-transparent'"
              class="rounded-full w-20 h-20 flex justify-center items-center">
          <BusinessIcon class="text-auxiliary-blue"/>
        </span>
        <span class="block my-4 text-auxiliary-blue body-m-700">Business</span>
        <p class="text-center body-xs-400">I am business wanting to hire a pro.</p>
      </div>
    </div>
    <ButtonIcon @onClick="next" text="Save and continue" :disabled="type == null" fontWeight="font-normal"/>
  </div>
</template>

<script>
import UserCircle from "@/components/shared/svg/UserCircle";
import BusinessIcon from "@/components/shared/svg/BusinessIcon";
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import Steps from "@/components/register/Steps";

import {mapMutations, mapState} from "vuex";

export default {
  name: "UserType",
  components: { Steps, ButtonIcon, BusinessIcon, UserCircle },

  mounted() {
    this.resetForm();
  },

  methods: {
    ...mapMutations({
      increaseStep: 'auth/increaseStep',
      setType: 'auth/setType',
      resetForm: 'auth/resetForm',
      showModal: 'general/showModal',
    }),

    next() {
      if (this.type === 'pro') {
        this.increaseStep();
        this.$router.push({name: 'UserForm'})
      } else {
        this.openSignupModal();
      }
    },

    openSignupModal() {
      this.setType('church');
      this.showModal({
        componentName:
        'SignupModal',
        minWH: 'min-width: 500px; min-height: 640px;',
        maxWH: 'max-w-md max-h-96 lg:max-h-180 2xl:max-h-192'
      });
    },
  },

  computed: {
    ...mapState({
      type: state => state.auth.type,
    }),
  },
}
</script>

<style scoped>
.card {
  @apply px-9 py-6 flex flex-col items-center rounded-xxl cursor-pointer transition-all duration-300;
}

.borderActive {
  background: #EEF3FF;
  border-color: #2C5DE5;
  border-width: 2px;
  border-radius: 12px;
  @apply shadow-2xl;
}

.borderInactive {
  border-color: transparent;
  @apply border-2;
}
</style>
