<template>
  <div class="flex space-x-5 justify-end">
    <span :class="{active:currentStep === 1}" class="step">1</span>
    <span :class="{active:currentStep === 2}" class="step">2</span>
    <span :class="{active:currentStep === 3}" class="step">3</span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Steps",
  computed: {
    ...mapState({
      currentStep: state => state.auth.registerStep
    }),
  }
}
</script>

<style scoped>
.step {
  @apply rounded-full inline-block w-10 h-10 flex justify-center items-center bg-white;
}

.active {
  @apply bg-secondary-main text-white transition-all duration-300;
}
</style>
