<template>
  <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M26.5 46.375V46.375C15.5224 46.375 6.625 37.4776 6.625 26.5V26.5C6.625 15.5224 15.5224 6.625 26.5 6.625V6.625C37.4776 6.625 46.375 15.5224 46.375 26.5V26.5C46.375 37.4776 37.4776 46.375 26.5 46.375Z"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M30.0134 16.9137C31.9539 18.8541 31.9539 22.0001 30.0134 23.9406C28.073 25.881 24.927 25.881 22.9866 23.9406C21.0461 22.0001 21.0461 18.8541 22.9866 16.9137C24.927 14.9733 28.073 14.9733 30.0134 16.9137"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
      d="M36.0424 36.528C35.5544 33.4606 32.9176 31.111 29.7155 31.111H23.2849C20.0806 31.111 17.446 33.4606 16.958 36.528"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "UserCircle"
}
</script>

<style scoped>

</style>
