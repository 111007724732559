<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_6703_74331)">
      <path
        d="M9.375 27.2656V40.625C9.375 41.0394 9.53962 41.4368 9.83265 41.7299C10.1257 42.0229 10.5231 42.1875 10.9375 42.1875H39.0625C39.4769 42.1875 39.8743 42.0229 40.1674 41.7299C40.4604 41.4368 40.625 41.0394 40.625 40.625V27.2656"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5469 7.8125H39.4531C39.7921 7.81529 40.1212 7.92688 40.3919 8.13082C40.6627 8.33477 40.8608 8.62029 40.957 8.94531L43.75 18.75H6.25L9.04297 8.94531C9.13921 8.62029 9.3373 8.33477 9.60805 8.13082C9.87881 7.92688 10.2079 7.81529 10.5469 7.8125Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.75 18.75V21.875C18.75 23.5326 18.0915 25.1223 16.9194 26.2944C15.7473 27.4665 14.1576 28.125 12.5 28.125C10.8424 28.125 9.25268 27.4665 8.08058 26.2944C6.90848 25.1223 6.25 23.5326 6.25 21.875V18.75"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.25 18.75V21.875C31.25 23.5326 30.5915 25.1223 29.4194 26.2944C28.2473 27.4665 26.6576 28.125 25 28.125C23.3424 28.125 21.7527 27.4665 20.5806 26.2944C19.4085 25.1223 18.75 23.5326 18.75 21.875V18.75"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.75 18.75V21.875C43.75 23.5326 43.0915 25.1223 41.9194 26.2944C40.7473 27.4665 39.1576 28.125 37.5 28.125C35.8424 28.125 34.2527 27.4665 33.0806 26.2944C31.9085 25.1223 31.25 23.5326 31.25 21.875V18.75"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6703_74331">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ChurchIcon",
};
</script>

<style scoped>
</style>
